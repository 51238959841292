import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { TransitionState } from "gatsby-plugin-transition-link"
import posed from "react-pose"

import { GlobalStateContext } from "../context/GlobalContextProvider"
import SEO from '../components/Seo'
import Layout from '../components/Layout'
// import TriggerButton from '../components/TriggerButton'
import Navigation from '../components/Navigation'

const PageFade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
})


const HomePage = (props) => {

  // console.log('Home page props: ', props)
  const state = useContext(GlobalStateContext)
  const metadata = props.data.homepage.metadata
  // const aboutUid = props.data.prismicAbout.uid
  // const aboutCopy = props.data.prismicLayout.data.about_rakuten_copy

  return (
    <Layout props={props}>
      <TransitionState>
        {({ transitionStatus }) => (
          <PageFade
            pose={
              ["entering", "entered"].includes(transitionStatus)
                ? "visible"
                : "hidden"
            }
          >
            <SEO
              locale={props.pageContext.lang}
              title={metadata.metatitle}
              description={metadata.description}
              keywords={metadata.keywords}
              image={metadata.metadata_image.localFile.childImageSharp.fixed.src}
              pathname={props.location.pathname}
              origin={props.location.origin}
            />
            {/* <TriggerButton icon="about" color="black" url={aboutUid} copy={aboutCopy} /> */}
            <div className={`home ${props.pageContext.lang} ${state.isLandingPlayed ? "played" : "playing"}`}>
              <Navigation template="full" locale={props.pageContext.lang} />
            </div>
          </PageFade>
        )}
      </TransitionState>
    </Layout>
  )
}

export const homeData = graphql`
  query HomeQuery($id: String!) {
    homepage: prismicHomeBodyMetadata(id: {eq: $id}) {
      metadata: primary {
        metatitle
        description
        keywords
        metadata_image {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default HomePage;
