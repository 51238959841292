import React from 'react'
import TransitionLink from "gatsby-plugin-transition-link"
// import BackgroundImage from 'gatsby-background-image'
import { motion } from "framer-motion"

const columnAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: i => ({
    opacity: 1,
    transition: {
      duration: .4,
      delay: i * 0.3,
      ease: "easeIn"
    }
  })
}

const logosAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: .5,
      delay: 2
    }
  },
}

const NavigationColumn = (props) => {

  // console.log("NavigationColumn props", props)
  const uid = props.data.uid
  const index = props.index
  const template = props.template
  const { background_colour, title, thumbnail } = props.data.data
  const businessUnits = props.data.data.business_units

  return (
    <motion.div
      className="navigation-column column"
      custom={index+1}
      initial="hidden"
      animate="visible"
      variants={columnAnimation}>
      <TransitionLink
        className={`navigation-column__link ${template}`}
        style={{backgroundColor: background_colour}}
        to={uid}
        exit={{ length: 0.5 }}
      >
        {template === "mini" && <h2 className="title is-size-1 has-text-white has-text-centered">
            {title.text}
          </h2>
        }

        {template === "full" && <div className="navigation-column__content">
          <motion.div
            className="navigation-column__bg"
            style={{ backgroundImage: `url(${thumbnail.localFile.childImageSharp.fluid.src})`}}
            custom={index+3}
            initial="hidden"
            animate="visible"
            variants={columnAnimation}>
          </motion.div>
            <motion.h2
              className="title is-size-3-mobile is-size-2-tablet is-size-1-fullhd has-text-white has-text-centered"
              custom={index+4}
              initial="hidden"
              animate="visible"
              variants={columnAnimation}>
              {props.data.data.title.text}
            </motion.h2>
            <motion.div
              className="navigation-column__logos has-text-centered"
              initial="hidden"
              animate="visible"
              variants={logosAnimation}>
              {businessUnits.map((unit, index) => {
                return (
                  <img
                    src={unit.logo.url}
                    alt={unit.logo.alt}
                    key={index}/>
                )
              })}
            </motion.div>
          </div>
        }

      </TransitionLink>
    </motion.div>

  )
}

export default NavigationColumn
