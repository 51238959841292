import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { motion } from "framer-motion"

import NavigationColumn from "./NavigationColumn"

const navigationAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 1,
      delay: .2
    }
  },
}

const Navigation = (props) => {

  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allPrismicHome {
        edges {
          node {
            lang
            data {
              columns {
                column {
                  document {
                    id
                    uid
                    data {
                      background_colour
                      title {
                        text
                      }
                      thumbnail {
                        url
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1400, pngQuality: 80)  {
                              src
                            }
                          }
                        }
                      }
                      business_units {
                        logo {
                          url
                          alt
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allPrismicHome.edges.filter(edge => edge.node.lang === props.locale)[0]

  const template = props.template
  const columns = data.node.data.columns

  return (
    <motion.div
      className={`navigation container is-fluid ${template}`}
      initial="hidden"
      animate="visible"
      variants={navigationAnimation}>
      <div className={`navigation__columns columns is-desktop`}>
        {columns.map(({column}, index) => {
          return (
            <NavigationColumn
              index={index}
              template={template}
              data={column.document[0]}
              key={column.document[0].id}
              locale={props.locale}
            />
          )
        })}
      </div>
    </motion.div>
  )
}

export default Navigation
